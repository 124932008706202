import { BrickElement, defineCustomElement } from '@amedia/brick-template';

import { containerStyle } from './icon-styles';
import { brickIconTemplate } from './template';
import type { BrickIconData } from './types';

@defineCustomElement({
  selector: 'brick-icon-v2',
})
export class BrickIcon extends BrickElement implements BrickIconData {
  dataIconId?: string;
  dataIconText?: string;

  constructor() {
    super();
  }
  static get mirroredProps() {
    return ['data-icon-id', 'data-icon-text'];
  }

  async connectedCallback() {
    this.className = containerStyle();
    super.connectedCallback();
  }

  // render() {
  //   if (this.getAttribute('rendered') === 'true') {
  //     return;
  //   }

  //   this.innerHTML = this.template;
  // }

  // get template() {
  //   return brickIconTemplate(this.brickIconData);
  // }
  get HTML() {
    return brickIconTemplate({
      dataIconId: this.dataIconId || '',
      dataIconText: this.dataIconText || '',
    });
  }
}
